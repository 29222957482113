.dark .suggestion-container:hover {
  background-color: #16252b;
}

.dark .suggestion-product-details span:nth-child(1) {
  border-right: #ffffff 1px solid;
}

.dark .suggestion-product-details span:nth-child(2) {
  border-left: #565656 1px solid;
}

.dark .suggestion-logo-container {
  border: #f5f5f5 1px solid;
  background-color: #ffffff;
}

.dark .suggestion-label-free {
  color: #fff;
  background-color: #000;
}

.dark .status-labels {
  color: #62a420;
}

.dark .status-labels.status-label-danger {
  color: #e62700;
}

.dark .status-labels.status-label-success {
  color: #62a420;
}
