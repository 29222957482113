$margins: xs, sm, md, lg, xl;

.empty {
  margin: 24px auto;
  max-width: 465px;
  text-align: center;

  cds-icon {
    color: var(--cds-global-color-gray-700);
  }

  &-icon-rotate {
    cds-icon {
      animation: spin 10s linear infinite;
    }
  }

  &-margin {
    @each $size in $margins {
      &-#{$size} {
        margin: var(--cds-global-layout-space-#{$size}) auto;
      }
    }
  }

  &-align-left {
    margin: 0;
    max-width: 100%;
    display: flex;
    align-items: center;
    padding: 0.75rem;
    min-height: 3rem;
    text-align: left;
  }
}
