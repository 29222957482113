#pdf-render-scroll-container {
    height: 100%;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}

#pdf-render-main-container {
    width: 100%;
    display: block;
}

.pdf-canvas-page {
    padding: 0;
    display: block;
    position: relative;
    background: #fff;
    margin: 15px auto;
    overflow: visible;
    width: 100%;
    background-clip: content-box;
    height: auto;
}
