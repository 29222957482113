body {
    margin: 0;
    padding: 0;
}

.main-container {
    overflow-x: hidden;
}

.clr-control-label {
    font-weight: 500;
    color: #313131;
}
