html {
    scroll-behavior: smooth;
}

body {
    overflow: hidden;
    font-family: var(--clr-font, Metropolis,"Avenir Next","Helvetica Neue",Arial,sans-serif);
    --cds-global-typography-font-family: var(--clr-font, Metropolis,"Avenir Next","Helvetica Neue",Arial,sans-serif);
    --cds-global-typography-header-font-family: var(--clr-font, Metropolis,"Avenir Next","Helvetica Neue",Arial,sans-serif);
    --cds-global-typography-color-500: #565656;
}

.app {
    text-align: center;
}

.app-logo {
    animation: app-logo-spin infinite 20s linear;
    height: 80px;
}

.app-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.app-title {
    font-size: 1.5em;
}

.app-intro {
    font-size: large;
}

@keyframes app-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.card-container h2 {
    display: inline-block;
}

.card-container .add-product-btn {
    float: right;
}

.pageLoader {
    width: 100%;
    text-align: center;
    margin-top: 200px;
}

.csp-page-loader-header {
    font-family: "Avenir Next", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 2em;
    margin: .67em 0;
    line-height: 48px;
}

.content-container {
    position: relative;
}

.content-container .content-area {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    padding: 0 !important;
}

.styler-enter {
    opacity: .01;
}

.styler-enter.styler-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.styler-leave {
    opacity: 1;
}

.styler-leave.styler-leave-active {
    opacity: .01;
    transition: opacity 300ms ease-in;
}

.platformIcon {
    padding-right: 10px;
    height: 20px;
}

.spinner-container {
    position: absolute;
    right: 52%;
    top: 50px;
}

.content-area{
    scroll-behavior: smooth;
}

/* Overriding style which is coming from CSP header angular component */
.header .header-actions .nav-link{
    padding-top: 10px;
}
button.btn-sign-in {
    margin : 0px !important;
    border: none;
    padding: 0px;
    line-height: 1;
}


.main-container .content-container .content-area .old-layout-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 15px 40px 15px 50px !important;
}

.main-container .content-container {
    flex: 1 0 auto !important;
}

.sticky-top {
    position: initial;
}

.main-container.no-scroll, 
.main-container.no-scroll .content-container, 
.main-container.no-scroll .content-container .content-area {
    overflow-y: hidden !important;
}

.main-container {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

/* Header style fix */
/* This is required as we are running Clarity 4, once we move to Clarity 5 this is not required */
header .branding .clr-icon, header .branding cds-icon, header .branding clr-icon, .header .branding .clr-icon, .header .branding cds-icon, .header .branding clr-icon {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 1.8rem;
    width: 1.8rem;
    margin-right: .45rem
}

.hide-refresh-notification {
    display: none;
}

.show-refresh-notification {
    display: block;
    cursor: pointer;
}