$margins: xs, sm, md, lg, xl, xxl;

.spinner {
  &-container {
    display: flex;
    position:unset;

    &.spinner-center {
      width: 100%;
      justify-content: center;
    }
  }

  &.spinner-inline {
    display: inline-block;
    margin-right: 6px;
  }

  &-margin {
    @each $size in $margins {
      &-#{$size} {
        margin: var(--cds-global-layout-space-#{$size}) 0;
      }
    }
  }
}
