.newsletter-modal-wrapper .successfull-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.newsletter-modal-wrapper .successfull-wrapper .sorry-text {
  margin-top: 14px;
}

.newsletter-modal-wrapper .successfull-wrapper .mistake-mssg {
  margin-top: 14px;
}

.newsletter-modal-wrapper .successfull-wrapper .success-subscription-mssg {
  margin-top: 26px;
}

.newsletter-modal-wrapper .successfull-wrapper .success-unsubscription-mssg{
  margin:0;
}

.newsletter-modal-wrapper .successfull-wrapper .success-mssg-container {
  width: 80%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.newsletter-modal-wrapper .successfull-wrapper .re-subscribe{
  border: none;
  background: transparent;
  color: #1D428A;
  cursor: pointer;
}
.newsletter-modal-wrapper .successfull-wrapper .re-subscribe:hover{
  text-decoration: underline;
}

.newsletter-form-wrapper .clr-form-control {
  margin: 0;
}
.newsletter-form-wrapper .clr-form-control > label {
  width: 90%;
  color: #565656;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}
.newsletter-form-wrapper .field-wrapper .subtext {
  padding: 7px 0 0 24px;
  text-align: left;
  font-size: 14px;
  width: 80%;
  display: inline-block;
}

.newsletter-form-wrapper .checkbox-grp-wrapper {
  margin-left: 24px;
}
.newsletter-form-wrapper .checkbox-grp-wrapper .clr-form-control > label {
  font-weight: 600;
  margin: 10px 0;
}

.newsletter-form-wrapper
  .checkbox-grp-wrapper
  .clr-form-control
  .clr-control-container
  > .clr-checkbox-wrapper {
  margin-bottom: 8px;
}
.subscription-form-wrapper .field-wrapper:first-child {
  margin-bottom: 15px;
}

.newsletter-form-wrapper .newsletter-error-wrapper{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5rem 0;
  width: 100%;
}

.newsletter-form-wrapper .newsletter-error{
  color: red;
  font-size: 13px;
  margin-left:10px;
  width: 90%;
}

.newsletter-modal-wrapper .vm-modal-wrapper {
  width: 580px;
}