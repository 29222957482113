
.button {
  // Override default margin for Clarity
  margin-right: 0 !important;

  & + .button {
    margin-left: 0.5rem;
  }

  &.btn-icon {
    cds-icon {
      margin-right: 6px;
    }
  }
}
