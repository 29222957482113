.legal-agreement-modal .vm-modal-wrapper {
    width: 90% !important;
    height: 90vh;
}

.legal-agreement-modal .vm-modal-body {
    border: 1px solid rgba(101, 101, 101, 0.1411764705882353);
    border-radius: 4px;
    position: relative;
    padding-bottom: 0 !important;
    display: flex;
    flex-direction: column;
}

.la-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.la-modal-col {
    display: flex;
}

.la-modal .btn-icon clr-icon {
    margin-right: 10px;
}

.la-modal-loader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
