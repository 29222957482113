.error-code,
.error-title,
.error-description p {
    padding: 0;
    margin: 0;
    color: #9a9a9a;
    font-weight: 400;
    line-height: 1.5em;
}

.error,
.error-description {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.error-code {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 144px;
}

.error-title {
    margin-bottom: 30px;
    font-size: 24px;
}


.header-actions button.nav-icon clr-icon {
    margin: 16px;
}

.header-actions .nav-icon {
    background: 0 0;
    border: none;
    color: #fafafa;
    opacity: 0.65;
    padding: 0 2px;
    outline: 0;
    cursor: pointer;
    position: relative;
}

.header-actions button.nav-icon.app-switcher {
    background-color: #293945;
}

.header-actions button.nav-icon.app-switcher.dark {
    background-color: #0b0f10;
}

header .header-actions form.search label::before {
    margin-right: 6px;
}

.nav-icon-divider::after {
    position: absolute;
    content: "";
    display: inline-block;
    background: #fafafa;
    opacity: 0.15;
    height: 40px;
    width: 1px;
    top: 10px;
}

.nav-icon-divider:hover::after {
    display: none;
}

.nav-icon:hover,
.nav-icon-text:hover {
    background-color: #25333d;
    opacity: 1;
}

.dropdown {
    display: inline-block;
}

@media (min-width: 768px) {
    .header .header-actions .nav-icon.user-menu {
        padding: 0 0 0 18px;
        width: auto;
        text-align: right;
        font-size: 0.9em;
        line-height: 1.3em;
    }

    .header .header-actions .nav-icon.user-menu clr-icon[shape="user"] {
        display: none;
    }

    .header .header-actions .nav-icon.user-menu clr-icon[shape="caret"] {
        margin: 15px 18px 15px 12px;
        float: right;
        display: block;
    }

    .header .header-actions .nav-icon.user-menu div {
        display: block;
        font-weight: 400;
        max-width: 136px;
        margin-right: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .header .header-actions .nav-icon.user-menu div .user-name {
        font-size: 15px;
        line-height: 24px;
    }

    .header .header-actions .nav-icon.user-menu div .org-name {
        font-size: 13px;
        line-height: 14px;
    }
}

.org-details {
    font-size: 0.9em;
    position: relative;
}

.org-details .org-dropdown {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 22px;
    opacity: 1;
    height: 20px;
    line-height: 20px;
    font-weight: 500;
}

.org-details .org-dropdown button {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 28px 0 0 !important;
    font-size: 22px;
    opacity: 1;
    height: 20px;
    line-height: 20px;
    font-weight: 500;
}

.org-details .no-org,
.org-details .no-orgs,
.org-details .org-id {
    display: block;
    opacity: 0.8;
    color: #d9e4ea;
    font-weight: 400;
    margin-top: -2px;
}

.user-details {
    margin-top: 36px;
}

.user-details .name {
    font-size: 22px;
    font-weight: 500;
    color: #fafafa;
}

.user-details .customer-id {
    display: block;
    opacity: 0.8;
    color: #d9e4ea;
    font-weight: 400;
    margin-top: -2px;
    font-size: 0.9em;
}

.drawer-container .drawer section label {
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    opacity: 0.8;
    color: #d9e4ea;
    font-weight: 600;
    font-size: 13px;
}

.error-description .alert-text a,
.error-description .alert-text a:visited,
.error-description .alert-text a:link {
    text-decoration: underline;
    color: #fff !important;
}

.service-icon {
    height: 28px;
    width: 28px;
    float: left;
}

.branding .title sup {
    padding-left: 5px;
}

.header .header-actions .nav-link,
.header .header-nav .nav-link,
.header .settings .nav-link,
header .header-actions .nav-link,
header .header-nav .nav-link,
header .settings .nav-link {
    position: relative;
    display: inline-block;
    text-align: center;
    color: #fafafa;
    opacity: 0.65;
}

.header .header-actions .nav-link.sign-in {
    padding: 0 !important;
}

header .header-actions .nav-link.nav-icon-text .nav-text,
header .header-nav .nav-link.nav-icon-text .nav-text,
header .settings .nav-link.nav-icon-text .nav-text {
    margin-left: 0;
    padding: 0 24px 0 15px;
}

header .header-actions .nav-link.nav-icon-text .nav-text {
    line-height: 60px;
    padding: 0 24px 0 15px;
}


header .header-actions .nav-link.nav-icon-text .nav-text.no-icon-text {
    margin-left: 18px;
}

header .header-actions .nav-link.nav-icon-text clr-icon,
header .header-nav .nav-link.nav-icon-text clr-icon,
header .settings .nav-link.nav-icon-text clr-icon {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: none;
    transform: none;
    margin-left: 1rem;
}

header .header-actions .nav-link.nav-icon-text.ext-link {
    padding: 0 0 0 20px;
}

header .header-actions .nav-link.nav-icon-text.ext-link clr-icon {
    margin-left: 5px !important;
    vertical-align: baseline;
}

.no-access {
    padding: 50px;
}

.no-access .alert-icon{
    color: #FAC400
}

.no-access .error-description {
    max-width: 454px;
}

.no-access .main-message {
    color: #565656;
    font-size: 18px;
    text-align: center;
    margin-top: 18px;
}

.no-access .sub-message {
    font-size: 14px;
    text-align: center;
    width: 370px;
    margin: 10px 0;
    color: #565656;
}

.no-access .alert-info {
    width: 402px;
    margin-top: 80px;
    line-height: 24px;
}

.no-access .csp-org-dropdown {
    width: 100%;
}

.no-access .csp-org-dropdown-button {
    padding: 0 8px 0 0;
    margin-bottom: 0;
}

.no-access .csp-org-dropdown-light.csp-org-dropdown-open .csp-org-dropdown-button {
    background-color: #f6f7f8;
    border: 1px solid #a5a5a5;
}

.no-access .csp-org-dropdown-list {
    border: 1px solid #464646;
    background-color: #ffffff;
    padding: 4px 0;
}

.no-access .csp-org-dropdown li {
    cursor: pointer;
    padding-left: 8px;
}

.no-access .csp-org-dropdown li:hover {
    cursor: pointer;
    background-color: #d0d0d0;
}