.captcha-wrapper{
    display: flex;
    width: 260px;
    height: 104px;
    border: #9a9a9a 1px solid;
    justify-content: space-between;
    flex-wrap: wrap;
}

.captcha-wrapper .captcha-loading {
    display: inline-block;
    margin: auto;
}

.captcha-wrapper .captcha-loading .spinner {
    margin-right: 5px;
}

.captcha-wrapper .captcha-img, .captcha-wrapper .captcha-refresh {
    display: flex;
}

.captcha-wrapper .captcha-img {
    width: 60%;
}

.captcha-wrapper .captcha-img img {
    margin: auto;
}

.captcha-wrapper .captcha-refresh {
    padding: 10px 10px 0px 10px
}

.captcha-wrapper .captcha-refresh button {
    margin: auto;
}

.captcha-wrapper .input-area input {
    border: #9a9a9a 1px solid;
    height: 36px;
    padding: 2px 4px;
    font-size: 14px;
    margin: 4px;
}

.captcha-wrapper .error-view {
    width: 100%;
    padding: 2px 18px;
    display: flex;
    flex-direction: column;
}

.captcha-wrapper .error-view button {
    width: 40px;
    margin: auto;
}

.captcha-wrapper .captcha-img{
    background-color: #ffffff;
    margin-left: 4px;
}
