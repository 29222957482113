@import "../../../src/scss/palette.scss";

.alert-styling{
    display: block;
    border-radius: 5px;
    width: 100%;
    margin: 0.5rem 0;
    padding: 0.2rem 0.5rem 0.2rem 1rem;
}

.alert-styling.alert-error{
    border: 1px solid #d4a097; 
    background: #feddd7;
    fill: red;
}

.alert-styling.alert-danger {
    border: 1px solid color(danger, dark-orange);
    background: color(danger, light-orange);
    fill: color(danger, primary);
}

.alert-styling.alert-warning{
    border: 1px solid #dcc697;
    background: #fff4c7
}

.alert-styling.alert-info{
    border: 1px solid #97b9c7;
    background: #e3f5fc
}

.alert-styling.alert-success{
    border:1px solid #aac297;
    background:#dff0d0
}

.alert-styling .alert-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.alert-styling .alert-section{
    width: 80%;
}
.alert-styling .alert-section-full-width{
    width: 100%;
}
.alert-styling .alert-text{
    font-size: 13px;
    margin-left: 10px;
    width: 100%;
}

.alert-styling .alert-icon{
    display: block;
    margin-right: 10px;
    padding-top: 3px;
}

.alert-styling .btn-primary {
    font-size: 10px;
}