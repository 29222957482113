.suggestion-container {
    display: flex;
    flex-direction: row;
    padding: 12px;
    position: relative;
    cursor: pointer;
    min-width: 424px;
    width: 100%;
}

.suggestion-container:hover {
    background-color: rgb(217, 228, 234);
}

.suggestion-container-active {
    background-color: rgb(217, 228, 234);
}

.suggestion-product-name {
}

.suggestion-product-details {
    line-height: 16px;
    margin-bottom: 6px;
}

.suggestion-logo-container {
    width: 66px;
    height: 66px;
    border: #f5f5f5 1px solid;
    margin: 0 12px;
    display: flex;
    justify-content: center;
    background-color: #ffffff;
}

.suggestion-logo {
    width: 60px;
    align-self: center;
}

.deployment-platform-icon {
    margin-right: 9px;
}

.product-card-tape {
    position: absolute;
    display: block;
    right: 0;
    width: 80px;
    height: 42px;
    overflow: hidden;
    bottom: 0;
}

.suggestion-label-free {
    position: relative;
    display: block;
    left: 36px;
    top: 7px;
    width: 60px;
    font-size: 11px;
    line-height: 134%;
    font-weight: 100;
    text-align: center;
    color: #fff;
    background-color: #000;
    -webkit-transform: rotate(45deg) translate3d(0, 0, 0);
    transform: rotate(45deg) translate3d(0, 0, 0);
}

.status-labels {
    height: 13px;
    color: #62a420;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 13px;
    text-align: center;
}

.status-labels.status-label-danger {
    color: #e62700;
}

.status-labels.status-label-info {
    color: #0079b8;
}

.status-labels.status-label-success {
    color: #62a420;
}

.icons-statuses-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.details-divider{
    --color: #666666;

}
