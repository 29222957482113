.tell-us-wrapper > p {
    font-size: 13px;
}

.categoies-feedback-wrapper {
    display: flex;
    width: 100%;
    margin: 25px 0;
}

.categoies-feedback-wrapper .select-wrapper{
    width: 37%;
    padding-right: 30px;
}

.feedback-description-wrapper .clr-control-container {
    width: 100%;
}

.feedback-description-wrapper .subText-wrapper {
    display: flex;
    font-size: 12px;
}

.feedback-description-wrapper .subText-err  {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    width: 70%;
}

.feedback-description-wrapper .error-msg,
.captcha-style .error-msg {
    color: #c21d00;
}

.feedback-description-wrapper .clr-control-container .clr-textarea {
    width: 70%;
    height: 120px;
}

.willing-section-wrapper .clr-control-container {
    display: flex;
}

.willing-section-wrapper .clr-radio-wrapper {
    padding-right: 20px;
    width: auto !important;
}

.willing-section-wrapper .clr-form-control > label {
    width: auto;
}

.select-wrapper .crc-label-text {
    padding-bottom: 10px;
}

.subText-wrapper .character-count {
    width: 70%;
    display: flex;
    justify-content: flex-end;
}

.user-form-wrapper {
    display: flex;
}

.user-form-wrapper .clr-form-control {
    width: 37%;
    padding-right: 40px;
}

.user-form-wrapper .clr-control-container {
    width: 100%;
    display: inline-block;
}
.user-form-wrapper .clr-input-wrapper {
    width: 100%;
}

.user-form-wrapper .clr-input {
    width: 100%;
}

.feedback-description-wrapper .crc-label-text.required::after,
.categoies-feedback-wrapper .crc-label-text.required::after,
.user-form-wrapper .crc-label-text.required::after {
    content: "*";
    color: #ff1300;
}

.captcha-style {
    margin-top: 1.2rem;
}

.captcha-style div.captcha-wrapper {
    width: 70%;
    border-radius: 3px;
    border-color: #b3b3b3;
}