@import "../../../src/scss/palette.scss";

.subnav .right-section {
    width: initial;
}
.new-homepage.subnav {
    justify-content: space-evenly;
}

.subnav .right-section .nav-item {
    position: relative;
}

.subnav .right-section .nav-item .btn-link{
    color: color(text, base);
}

.subnav .right-section .nav-item:not(:last-child)::after {
    content: '';
    display: inline-block;
    background-color: #b3b3b3;
    height: 20px;
    width: 1px;
    position: absolute;
    top: 14px;
    right: -10px;
}

.subnav .dropdown button.dropdown-toggle:not(.btn){
    color: color(text, base);
}

.subnav .dropdown .dropdown-item:hover{
    color: color(neutral, black);
    background-color: color(bg, shadegray);
}

.subnav .dropdown .heading-subnav-button{
    font-weight: 500;
    color: color(neutral, black);
    background-color: transparent;
    cursor: auto;
}

.subnav .dropdown .enroll-element-wrapper{
    width: 300px;
    white-space: initial;
}

.subnav .dropdown .enrolled-section-wrapper{
    display: flex;
}
.subnav .dropdown .heading-subnav-button:not(:first-child){
    margin-top: 1.9rem;
}

.subnav .dropdown .heading-subnav-button:hover, .subnav .dropdown .display-item-subnav:hover{
    background: color(neutral, white);
}

.subnav .dropdown .display-item-subnav{
    overflow: hidden;
    text-overflow: clip;
    cursor: auto;

}

.landingpage-search-wrapper{
    width: 100%;
    position: absolute;
    display: flex;
}

.landingpage-search-wrapper .search-bar-wrapper{
    margin: auto;
    margin-top: -35px;
    margin-left: 34rem;

}

.landingpage-search-wrapper .search-bar-wrapper .search-bar-input {
    border: 1px solid #d4d4d4;
    font-size: 14px;
    height: 29px;
    width: 450px;
    border-radius: 4px;
    background-color: color(bg, white);
    padding: 0 30px 0 10px;
}

.landingpage-search-wrapper .search-bar-wrapper .search-bar-input::placeholder {
    color: #000000;
}

.subnav {
    height: 2rem;
}

.subnav .nav {
    box-shadow: none;
}

.sticky-top .nav .nav-item,
.sticky-top .nav-item .dropdown,
.sticky-top .nav-item .dropdown .dropdown-toggle {
    height: inherit;
}

.nav-item.active-nav-item button.dropdown-toggle{
    border-bottom: 3px solid color(action, shadow);
}

.right-section .right-section-nav-item {
    margin: 0;
}

.navbar-hamburger {
    display: none;
}

@media screen and (max-width: 600px) {
    .subnav.new-homepage {
        .navbar-hamburger {
            display: block;
        }

        .nav li:not(:first-child) {
            display: none;
        }
    }

    .subnav.expanded.new-homepage {
        height: auto;
        align-items: flex-start;
        justify-content: space-evenly;
        
        .nav {
            height: auto;
            color: white;
            position: relative;
            width: unset;
        }
        .nav, li.nav-item {
            display: block;
            z-index: 1;
            background-color:inherit;
        }

        li.nav-item {
            height: 2rem;
            margin: 0;
        }

        .nav .dropdown.bottom-left>.dropdown-menu{
            left: 3rem;
        }
    }
}
