footer {
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    background: #FFFFFF;
    margin-top: auto; /* to keep the footer stick to the bottom of the page. */
    margin: 1.2rem 50px;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-wrap: wrap;
}

.footer-links > div {
    width: 18%;
}

.footer-links > div:first-child {
    width: 28%;
}

.footer-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer-section .footer-link-title {
    font-family: Metropolis;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 17px;
}

.footer-text-container {
    max-width: 220px;
}

.footer-text-content {
    font-family: Metropolis;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.footer-section .footer-link,
.footer-section .feedback-link {
    font-family: Metropolis;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    display: block;
    margin-bottom: 16px;
}

.footer-section .feedback-link {
    background-color: transparent;
    border: none;
    padding: 0;
 }

.footer-section .feedback-link:hover {
    cursor: pointer;
}

.footer-link-icon {
    margin-left: 8.82px;
}

.footer-login-links-container {
    display: flex;
    flex-direction: column;
    height: 50px;
    justify-content: space-between;
}

.footer-login-link {
    font-family: Metropolis;
    font-size: 14px;
    line-height: 24px;
    color: #1D428A;
}

.footer-login-links-container a:visited{
    color: #1D428A;
}

.footer-link-button {
    padding: 0;
    width: max-content;
    color: #1D428A;
    border: none;
    background: none;
    cursor: pointer;
}

.footer-link-button:hover {
    text-decoration: underline;
}

.footer-social-container {
    display: flex;
    margin: 66px 0 40px 0;
}

.footer-social-link {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 34px;
    justify-content: center;
    width: 34px;    
    margin-right: 19px;
}

.footer-privacy-container {
    font-family: Metropolis;
    font-size: 12px;
    line-height: 16px;
    color: #62727E;
}

.utility-content-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.utility-content-container > a {
    width: 18%;
    color: #62727E;
}

.utility-content-container > a.privacy-policy {
    width: 36%;
}

.utility-content-container > a.footer-link-text {
    width: fit-content;
}

.privacy-links-container {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.footer-logo {
    align-self: flex-end;
    margin: 28px 0 10px 0;
}

.footer-logo img {
    width: 112px;
}

.footer-container a:hover {
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .footer-links > div, .footer-links > div:first-child, .utility-content-container > a, .utility-content-container > div:first-child, .utility-content-container > a.privacy-policy {
        width: 100%;
    } 
  }

footer.listing-footer {
    margin-left: 0;
    margin-right: 0;
}

.footer-link-text{
    width: fit-content;
}

.footer-social-icon{
    width: 100px;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}