.vm-modal,
.vm-modal-backdrop {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.vm-modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    opacity: 0;
    pointer-events: none;
    z-index: 1050;
    transition: opacity 0.2s ease;
}

.vm-modal.show {
    opacity: 1;
    pointer-events: inherit;
    transition: opacity 0.2s ease;
}

.vm-modal-backdrop {
    position: absolute;
    background-color: #313131;
    opacity: 0.85;
    z-index: -1;
}

.vm-modal-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: -2rem;
    padding: 1rem;
    width: 50%;
    max-width: 100% !important;
    max-height: 100% !important;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.07);
    overflow: hidden;
    transition: margin-top 0.2s ease;
}

.vm-modal-small .vm-modal-wrapper {
    width: 25%;
}

.vm-modal-smallmedium .vm-modal-wrapper {
    width: 576px;
}

.vm-modal-medium .vm-modal-wrapper {
    width: 864px;
}

.vm-modal-large .vm-modal-wrapper {
    width: 75%;
}

.vm-modal-extra-large .vm-modal-wrapper {
    width: 100%;
}

.vm-modal.show .vm-modal-wrapper {
    margin-top: 0;
}

.vm-modal-header,
.vm-modal-footer {
    flex-shrink: 0;
}

.vm-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.vm-modal-header .vm-modal-header-title {
    margin: 0;
    font-size: 22px;
    font-weight: 300;
    color: #000;
    word-break: break-word;
}

.vm-modal-header .vm-modal-header-close {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 0 0 6px;
    min-width: inherit;
    width: 24px;
    height: 24px;
    color: #737373;
    border: none;
    background-color: transparent;
}

.vm-modal-header .vm-modal-header-close:hover {
    color: #004a70;
    background-color: transparent;
}

.vm-modal-header .vm-modal-header-close:active {
    box-shadow: none;
}

.vm-modal-header .vm-modal-header-close clr-icon {
    transform: none;
}

.vm-modal-body {
    padding: 1px 1rem;
    margin: 0 -1rem;
    height: 100%;
    overflow: auto;
}

.vm-modal-body.is-loading, .vm-modal-header.is-loading{
    pointer-events: unset;
    opacity: 0.5;
    cursor: default;
}

.vm-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 1rem;
}

.vm-modal-footer > button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.vm-modal-footer .spinner {
    margin-left: 6px;
}

.vm-modal-footer > button:not(:first-of-type) {
    margin-left: 0.5rem;
}

.vm-modal-footer > .modal-error-message{
    color: #ff0000;
    padding-right: 10px;
}
