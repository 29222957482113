@use "../../scss/media-queries-mixins.scss" as *;

.search-bar-wrapper{
    z-index: 999;
    .input-wrapper{
        background-color: transparent;
        width: 510px;
        max-width: 510px;
        border: 1px solid #8C8C8C;
        height: 48px;
        border-radius: 3px;
        box-sizing: border-box;
        &.compact{
            height: 30px;
            input{
                font-size: 14px;
            }
        }
        &.light{
            background-color: #F1F1F1;
        }
        &.adaptive{
            width: 100%;
        }
        &:focus-within{
            border: 1px solid #0072A3;
            background-color: #fff;
        }
        .search-icon{
            width: 48px;
            height:48px;
            background-color: #0072A3;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            &.light{
                background-color: transparent;
                color: #0072A3;
            }
        }
        input{
            border: none;
            outline: none;
            font-size: 16px;
            line-height: 16px;
            background-color: transparent;
        }
    }
    .result-view-wrapper{
        position: relative;
        display: flex;
        z-index: 99;
        max-width: 510px;
        .result-view-content{
            position: absolute;
            top: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: #FAFAFA;
            border-top: #FAFAFA 1px solid;
            box-shadow: 0px 2px 4px rgba(115, 115, 115, 0.22);
            .loading-view-wrapper{
                padding: 20px 10px;
                box-shadow: dimgrey 0 4px 6px 0px;
                .status-text {
                    margin: auto;
                    font-weight: 500;
                }
            }
            .search-result-wrapper {
                box-shadow: dimgrey 0 4px 6px 0px;
                .search-result-header {
                    height: 40px;
                    display: flex;
                    background-color: #f5f5f5;
                    width: 100%;
                    justify-content: space-between;
                    padding: 8px 14px;
                    font-size: 15px;
                    font-weight: 500;
                    .header-link {
                        color: #0079b8;
                        text-decoration: underline;
                    }
                }
                .list-view {
                    overflow: scroll;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    animation: expandListSection .2s linear forwards;
                    list-style: none;
                }
                .search-item {
                    display: flex;
                    width: 100%;
                    height: 80px;
                    border: #5b5b5b 1px solid;
                }
            }
        }
    }

    @keyframes expandSection {
        0% {
            height: 0;
        }
        100% {
            height: 50px;
        }
    }
    
    @keyframes expandListSection {
        0% {
            max-height: 0;
        }
        100% {
            max-height: 300px;
        }
    }
}

@include sm {
    .search-bar-wrapper{
        .input-wrapper{
            width: 310px;
        }
    }
}

@include xs {
    .search-bar-wrapper{
        .input-wrapper{
            width: 250px;
        }
    }
}