.success-modal-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.success-modal-wrapper .success-icon {
    color: #306b00;
    padding-bottom: 15px;
}