.app-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 599;
    background-color: #00000059;
    overflow: hidden;
}
