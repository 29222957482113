.dark .vm-modal-backdrop {
    position: absolute;
    background-color: #313131;
    opacity: 0.85;
    z-index: -1;
}

.dark .vm-modal-wrapper {
    background-color: #16252b;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.07);
}

.dark .vm-modal-header .vm-modal-header-title {
    color: #ffffff;
}

.dark .vm-modal-header .vm-modal-header-close {
    color: #ffffff;
}

.dark .vm-modal-header .vm-modal-header-close:hover {
    color: #004a70;
}
